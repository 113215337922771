.bg-reminder {
  background-color: rgb(219, 140, 76);
}

.bg-task {
  background-color: #5bc0de;
}

.bg-alert {
  background-color: #fd352e;
}

.bg-important {
  background-color: #f8c845;
}

.bg-pending {
  background-color: #f8c845;
}

.bg-approved {
  background-color: #5bc0de;
}

.bg-rejected {
  background-color: #fd352e;
}

.bg-awaiting_renewal {
  background-color: #f8c845;
}

.bg-renewal_rejected {
  background-color: #fd352e;
}
