.page-header {
  height: 70px;
  padding: 0 1.2rem;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

  .page-title {
    color: var(--primary-color);
  }
}
