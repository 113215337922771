.card {
  border: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.card-header {
  background-color: #fff;
  padding: 0.8rem 1rem;
}

.card-body {
  padding: 1rem;
}

.card-footer {
  border-top: 0;
  padding: 0.8rem 1rem;
}