.nav.nav-tabs {
  z-index: 99;
  position: relative;
  border-bottom: 0;

  > .nav-item {
    > .nav-link {
      color: var(--primary-color);
      margin-bottom: calc(-4 * var(--bs-nav-tabs-border-width));
      padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x) calc(var(--bs-nav-link-padding-y) + 4 * var(--bs-nav-tabs-border-width));
      border: 0;

      &.disabled {
        color: var(--gray-color);
      }

      &.active {
        background-color: #fff;
        color: var(--primary-color);
      }
    }
  }
}