h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1rem * 1.4);
}

h2 {
  font-size: calc(1rem * 1.32);
}

h3 {
  font-size: calc(1rem * 1.12);
}

p {
  margin-bottom: 1rem;
}

a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: var(--primary-color-ascent);
  }
}
