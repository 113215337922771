.btn-primary {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  font-size: 1rem;
}

.btn-outline-primary {
  color: var(--primary-color);
  background-color: transparent;
  background-image: none;
  border-color: var(--primary-color);
}

.btn-primary:hover,
.btn-outline-primary:hover {
  color: #fff;
  background-color: var(--primary-color-ascent);
  border-color: var(--primary-color-ascent);
}

.btn-primary:disabled {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-outline-primary:disabled {
  color: var(--primary-color);
  border-color: var(--primary-color);
  background-color: transparent;
}

.btn-secondary {
  color: #fff;
  background-color: var(--second-action-color);
  border-color: var(--second-action-color);
  font-size: 1rem;
}

.btn-outline-secondary {
  color: var(--second-action-color);
  background-color: transparent;
  background-image: none;
  border-color: var(--second-action-color);
}

.btn-secondary:hover,
.btn-outline-secondary:hover {
  color: #fff;
  background-color: var(--second-action-color-ascent);
  border-color: var(--second-action-color-ascent);
}

.btn-secondary:disabled {
  color: #fff;
  background-color: var(--second-action-color);
  border-color: var(--second-action-color);
}

.btn-primary > svg,
.btn-primary > svg > path {
  stroke: #fff;
}

.btn-secondary > svg,
.btn-secondary > svg > path {
  stroke: #fff;
}

.btn-outline-secondary:disabled {
  color: var(--second-action-color);
  border-color: var(--second-action-color);
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: var(--primary-color);
  text-decoration: none;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
